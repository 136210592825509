/* eslint-disable */

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-4">
      <vx-card class="">
        <div class="vx-row my-12">
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-container flex flex-col items-center align-middle" style="min-width: 14vw">
              <div
                class="border-solid p-2 mb-12 rounded-lg flex flex-col items-center justify-center mb-5 w-4/5 server-block cursor-pointer"
                @click="togglePopup('emailLogsPopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">Email Server</h3>
                <h5>192.168.0.20</h5>
              </div>
              <div class="border-solid p-2 rounded-lg flex flex-col items-center justify-center w-4/5 server-block">
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">Internal Router</h3>
                <h5>192.168.0.1</h5>
              </div>
            </div>
            <div class="line-up" />
          </div>
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-container flex flex-col items-center align-middle" style="min-width: 14vw">
              <div
                class="border-solid p-2 mb-12 rounded-lg flex flex-col items-center justify-center mb-5 w-4/5 server-block cursor-pointer"
                @click="togglePopup('fileLogsPopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">File Server</h3>
                <h5>192.168.0.102</h5>
              </div>
              <div class="border-solid p-2 rounded-lg flex flex-col items-center justify-center w-4/5 server-block">
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">Proxy</h3>
                <h5>192.168.0.50</h5>
              </div>
            </div>
            <div class="line-up" />
            <div class="line-across"></div>
          </div>
          <div class="vx-col w-1/3 flex justify-center items-center">
            <div class="server-container flex flex-col items-center align-middle" style="min-width: 14vw">
              <div
                class="border-solid p-2 mb-12 rounded-lg flex flex-col items-center justify-center mb-5 w-4/5 server-block cursor-pointer"
                @click="togglePopup('SIEMLogsPopup')"
              >
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">SIEM</h3>
                <h5>192.168.0.15</h5>
              </div>
              <div class="border-solid p-2 rounded-lg flex flex-col items-center justify-center w-4/5 server-block">
                <img
                  alt=""
                  class="tenth-image"
                  draggable="false"
                  src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/cysa-plus/344012904325971968/wireless-router.png"
                  width="60"
                />
                <h3 class="font-medium mt-2 text-center">Devices</h3>
                <h5>192.168.0.0/24</h5>
              </div>
            </div>
            <div class="line-up" />
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full">
      <vx-card class="">
        <div class="border-white border-solid border-2 rounded">
          <div
            :class="[
              reportAnalysisComplete === false ? 'bg-danger-gradient' : '',
              reportAnalysisComplete === true ? 'bg-success-gradient' : '',
            ]"
            class="flex items-center justify-between p-5 bg-primary-gradient"
          >
            <div class="flex">
              <feather-icon icon="FileTextIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Report Analysis</h3>
            </div>
            <div class="flex">
              <feather-icon v-if="reportAnalysisComplete === true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              <feather-icon v-if="reportAnalysisComplete === false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
            </div>
          </div>
          <div class="p-5 pt-3 pb-3">
            <h5 class="font-bold mb-2">View Phishing Email</h5>
            <vs-button
              color="primary"
              icon-pack="feather"
              icon="icon-mail"
              class="mb-6"
              type="gradient"
              @click="togglePopup('phishingEmailPopup')"
              >View Phishing Email
            </vs-button>

            <h5 class="mb-2">How many users clicked the link in the phishing email?</h5>
            <div class="flex items-center justify-between mb-6" style="max-width: 600px">
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="0">0</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="1">1</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="2">2</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="3">3</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="4">4</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="5">5</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="6">6</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="7">7</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="8">8</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="9">9</vs-radio>
              <vs-radio v-model="usersClickedLink.value" vs-name="users" vs-value="10">10</vs-radio>
            </div>

            <h5 class="mb-2">How many workstations were infected?</h5>
            <div class="flex items-center justify-between mb-6" style="max-width: 600px">
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="0">0</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="1">1</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="2">2</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="3">3</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="4">4</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="5">5</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="6">6</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="7">7</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="8">8</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="9">9</vs-radio>
              <vs-radio v-model="workstationsInfected.value" vs-name="workstation" vs-value="10">10</vs-radio>
            </div>

            <h5 class="mb-2">Select the malware executable name</h5>
            <v-select v-model="malwareName.value" :options="malwareName.options" id="malwareSelect" placeholder="Select from Dropdown" />
          </div>
        </div>
      </vx-card>
    </div>

    <movable-popup
      refVal="movableEmailRef"
      title="Email Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="emailLogsPopup"
      v-on:close="emailLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="emailLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Date/Time</vs-th>
            <vs-th>Protocol</vs-th>
            <vs-th>SIP</vs-th>
            <vs-th>Source Port</vs-th>
            <vs-th>From</vs-th>
            <vs-th>To</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['Protocol']}`">
                {{ `${attach['Protocol']}` }}
              </vs-td>

              <vs-td :data="`${attach['SIP']}`">
                {{ `${attach['SIP']}` }}
              </vs-td>

              <vs-td :data="`${attach['SP']}`">
                {{ `${attach['SP']}` }}
              </vs-td>

              <vs-td :data="`${attach['From']}`">
                {{ `${attach['From']}` }}
              </vs-td>

              <vs-td :data="`${attach['To']}`">
                {{ `${attach['To']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableFileRef"
      title="File Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="fileLogsPopup"
      v-on:close="fileLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="fileLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Date/Time</vs-th>
            <vs-th>Source IP</vs-th>
            <vs-th>Source Port</vs-th>
            <vs-th>Dest IP</vs-th>
            <vs-th>Dest Port</vs-th>
            <vs-th>URL</vs-th>
            <vs-th>Request</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['SIP']}`">
                {{ `${attach['SIP']}` }}
              </vs-td>

              <vs-td :data="`${attach['SP']}`">
                {{ `${attach['SP']}` }}
              </vs-td>

              <vs-td :data="`${attach['DIP']}`">
                {{ `${attach['DIP']}` }}
              </vs-td>

              <vs-td :data="`${attach['PORT']}`">
                {{ `${attach['PORT']}` }}
              </vs-td>

              <vs-td :data="`${attach['URL']}`">
                {{ `${attach['URL']}` }}
              </vs-td>

              <vs-td :data="`${attach['REQUEST']}`">
                {{ `${attach['REQUEST']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movableSIEMRef"
      title="SIEM Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="SIEMLogsPopup"
      v-on:close="SIEMLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="SIEMLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Keywords</vs-th>
            <vs-th>Date/Time</vs-th>
            <vs-th>Event ID</vs-th>
            <vs-th>Task Category</vs-th>
            <vs-th>Log Message</vs-th>
            <vs-th>IP Address</vs-th>
            <vs-th>Account Name</vs-th>
            <vs-th>Process ID</vs-th>
            <vs-th>Process Name</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['Keywords']}`">
                {{ `${attach['Keywords']}` }}
              </vs-td>

              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['EID']}`">
                {{ `${attach['EID']}` }}
              </vs-td>

              <vs-td :data="`${attach['TC']}`">
                {{ `${attach['TC']}` }}
              </vs-td>

              <vs-td :data="`${attach['LM']}`">
                {{ `${attach['LM']}` }}
              </vs-td>

              <vs-td :data="`${attach['IP']}`">
                {{ `${attach['IP']}` }}
              </vs-td>

              <vs-td :data="`${attach['AN']}`">
                {{ `${attach['AN']}` }}
              </vs-td>

              <vs-td :data="`${attach['PID']}`">
                {{ `${attach['PID']}` }}
              </vs-td>

              <vs-td :data="`${attach['PNAME']}`">
                {{ `${attach['PNAME']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>

    <movable-popup
      refVal="movablePhishingRef"
      title="Phishing Email - Click Here to Drag This Popup"
      :active="phishingEmailPopup"
      v-on:close="phishingEmailPopup = false"
    >
      <template v-slot:content>
        <h3>From: It HelpDesk &#60;<span class="text-primary">it-helpdesk@anycorp.com</span>&#62;</h3>
        <h3>Sent: Mon 3/7/2016 4:00PM</h3>
        <h3>To: Global Users &#60;<span class="text-primary">globalusers@sobergrill.com</span>&#62;</h3>
        <br />
        <h3 class="font-light">
          Hello,<br /><br />In the upcoming days, we will be moving our mail servers from MS Outlook to the new Netscape Navigator. Checkout
          the new SoberGrill webmail and know if it has started working for you.
        </h3>
        <br />
        <h3 class="font-light">Visit the new SoberGrill webmail to see all the new features</h3>
        <h3 class="font-light">Use your current username and password at <span class="text-primary underline">SoberGrill Webmail</span></h3>
        <br />
        <h3 class="font-light">Download the latest mail client <span class="text-primary underline">Here.</span></h3>
        <br />
        <h3 class="font-light">Thank You,</h3>
        <br />
        <h3 class="font-light">IT HelpDesk</h3>
      </template>
    </movable-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import MovablePopup from '../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      reportAnalysisComplete: null,
      usersClickedLink: {
        answer: '7',
        value: null,
        marked: null,
      },
      workstationsInfected: {
        answer: '4',
        value: null,
        marked: null,
      },
      malwareName: {
        answer: 'mailclient.exe',
        value: null,
        marked: null,
        options: [
          'notepad.exe',
          'cmd.exe',
          'winword.exe',
          'explorer.exe',
          'mailclient.exe',
          'time.exe',
          'svchost.exe',
          'iexplore.exe',
          'lsass.exe',
          'chrome.exe',
          'putty.exe',
          'minecraft.exe',
          'firefox.exe',
          'cand.exe',
          'torn.exe',
        ],
      },

      emailLogsPopup: false,
      emailLogs: [
        {
          DT: '3/7/2016 4:16:19 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.110',
          SP: '37196',
          From: 'kmatthews@anycorp.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 4:15:13 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.117',
          SP: '57888',
          From: 'stanimoto@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 4:14:25 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 4:13:02 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'jlee@anycorp.com;  adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 4:12:50 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 4:11:09 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.155',
          SP: '32891',
          From: 'kwilliams@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 4:10:54 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '46187',
          From: 'lbalk@anycorp.com',
          To: 'jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 4:10:38 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.181',
          SP: '34556',
          From: 'dfritz@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 4:10:23 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.155',
          SP: '32891',
          From: 'kwilliams@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 4:09:34 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 4:08:49 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 4:07:33 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 4:07:32 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.197',
          SP: '33585',
          From: 'gromney@anycorp.com',
          To: 'Ibalk@anycorp.com',
        },
        {
          DT: '3/7/2016 4:05:47 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'adifabio@anycorp.com; jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 4:04:24 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 4:03:50 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 4:03:25 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.181',
          SP: '34556',
          From: 'dfritz@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:37 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:37 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'sboaz@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:35 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ibenz@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:33 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'dsutherland@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:31 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Irossiter@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:30 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ahynson@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:30 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mdillion@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:30 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jwayman@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:28 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jrehn@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:28 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Irogge@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:27 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'aaveritt@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:25 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'lephraim@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:25 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'wmcnerney@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:23 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'imarable@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:23 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'tfausto@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:21 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kdefranco@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:21 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mworley@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:21 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Itreiber@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:20 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mgarneau@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:19 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'hfossum@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:19 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'trhoda@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:18 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ctsuji@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:16 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'sprosperie@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:14 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'bmonteleone@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:14 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cfenstermacher@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:14 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'rgarfinkel@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:13 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cheroux@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:13 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mkamen@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:12 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'zdodgen@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:10 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mhammonds@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:09 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'onorth@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:07 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mroane@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:05 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kbowling@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:05 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'nrachal@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:03 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jdegenhardt@anycorp.com',
        },
        {
          DT: '3/7/2016 4:01:01 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'wracette@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:59 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Ihammond@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:57 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'dmilazzo@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:55 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kneubauer@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:54 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'bboyko@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:54 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'dcrofoot@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:52 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jmemmott@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:52 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'chodgin@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:51 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'aholler@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:49 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'abattaglia@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:47 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'halberti@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:45 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'myeoman@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:45 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'wbobadilla@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:44 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Ikam@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:44 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jcooks@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:43 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cpolice@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:41 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mwagener@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:40 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'bteer@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:40 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Itabor@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:40 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Ioller@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:40 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kwilliams@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:40 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'rponds@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:38 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'tshack@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:37 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kmarson@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:35 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Islaughter@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:33 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'gleos@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:33 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'dstivers@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:33 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'msistrunk@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:33 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:32 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'Icreekmore@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:31 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ashockley@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:30 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'stanimoto@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:29 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'jmulcahy@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:28 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'tgorney@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:28 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'fbenware@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:27 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cgalipeau@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:26 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'gromney@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:26 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'epeavey@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:25 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ecordero@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:24 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:22 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cscalls@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:21 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'ckroeker@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:19 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'kinfantino@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:17 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:17 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'mhazan@anycorp.com',
        },
        {
          DT: '3/7/2016 4:00:15 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 3:59:11 PM',
          Protocol: 'TCP',
          SIP: '58.125.17.196',
          SP: '54566',
          From: 'it-helpdesk@sobergrill.com',
          To: 'khoward@anycorp.com',
        },
        {
          DT: '3/7/2016 3:57:58 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.110',
          SP: '37196',
          From: 'kmatthews@anycorp.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 3:56:04 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.117',
          SP: '57888',
          From: 'stanimoto@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:54:28 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:52:59 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'jlee@anycorp.com;  adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:52:15 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:51:41 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.155',
          SP: '32891',
          From: 'kwilliams@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 3:51:00 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '46187',
          From: 'lbalk@anycorp.com',
          To: 'jlee    @anycorp.com',
        },
        {
          DT: '3/7/2016 3:50:39 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 3:49:07 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 3:48:40 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 3:48:35 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.197',
          SP: '33585',
          From: 'gromney@anycorp.com',
          To: 'Ibalk@anycorp.com',
        },
        {
          DT: '3/7/2016 3:47:27 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'adifabio@anycorp.com; jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 3:46:06 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 3:46:05 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 3:44:45 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.60',
          SP: '31498',
          From: 'dfritz@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:44:02 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 3:43:36 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:41:56 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.110',
          SP: '37196',
          From: 'kmatthews@anycorp.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 3:41:43 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.117',
          SP: '57888',
          From: 'stanimoto@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:40:50 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:51:41 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'jlee@anycorp.com;  adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:51:00 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '46187',
          From: 'Ibalk@anycorp.com',
          To: 'jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 3:50:39 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 3:49:07 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
        {
          DT: '3/7/2016 3:48:40 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 3:48:35 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.197',
          SP: '33585',
          From: 'gromney@anycorp.com',
          To: 'Ibalk@anycorp.com',
        },
        {
          DT: '3/7/2016 3:47:27 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'adifabio@anycorp.com; jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 3:46:06 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.34',
          SP: '30364',
          From: 'asmith@anycorp.com',
          To: 'jlee@anycorp.com',
        },
        {
          DT: '3/7/2016 3:46:05 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'asmith@anycorp.com',
        },
        {
          DT: '3/7/2016 3:44:45 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.60',
          SP: '31498',
          From: 'dfritz@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:44:02 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.61',
          SP: '48734',
          From: 'cpuziss@anycorp.com',
          To: 'kmatthews@anycorp.com',
        },
        {
          DT: '3/7/2016 3:43:36 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:41:56 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.110',
          SP: '37196',
          From: 'kmatthews@anycorp.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 3:41:43 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.117',
          SP: '57888',
          From: 'stanimoto@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:40:50 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '53876',
          From: 'hparikh@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:39:16 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'jlee@anycorp.com;  adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:38:44 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.110',
          SP: '37196',
          From: 'kmatthews@anycorp.com',
          To: 'dfritz@anycorp.com',
        },
        {
          DT: '3/7/2016 3:38:22 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.117',
          SP: '57888',
          From: 'stanimoto@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:38:00 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.139',
          SP: '46550',
          From: 'hparikh@anycorp.com',
          To: 'adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:36:24 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.185',
          SP: '63616',
          From: 'jlee@anycorp.com',
          To: 'jlee@anycorp.com;  adifabio@anycorp.com',
        },
        {
          DT: '3/7/2016 3:35:03 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.47',
          SP: '60919',
          From: 'adifabio@anycorp.com',
          To: 'cpuziss@anycorp.com',
        },
        {
          DT: '3/7/2016 3:35:03 PM',
          Protocol: 'TCP',
          SIP: '192.168.0.155',
          SP: '32891',
          From: 'kwilliams@anycorp.com',
          To: 'hparikh@anycorp.com',
        },
      ],

      fileLogsPopup: false,
      fileLogs: [
        {
          DT: '3/7/2016 4:27:03 PM',
          SIP: '192.168.0.153',
          SP: '50467',
          DIP: '11.102.109.179',
          PORT: '80',
          URL: 'bestpurchase.com',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:26:51 PM',
          SIP: '192.168.0.245',
          SP: '60021',
          DIP: '72.104.64.186',
          PORT: '80',
          URL: 'visitorcenter.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:25:36 PM',
          SIP: '192.168.0.97',
          SP: '46354',
          DIP: '96.191.222.144',
          PORT: '80',
          URL: 'bestpurchase.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:25:10 PM',
          SIP: '192.168.0.116',
          SP: '43389',
          DIP: '35.132.243.140',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:25:06 PM',
          SIP: '192.168.0.7',
          SP: '45463',
          DIP: '124.140.208.241',
          PORT: '80',
          URL: 'stopthebotnet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:23:39 PM',
          SIP: '192.168.0.150',
          SP: '54460',
          DIP: '74.182.188.144',
          PORT: '80',
          URL: 'funweb.cn',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:21:39 PM',
          SIP: '192.168.0.211',
          SP: '54172',
          DIP: '165.11.148.28',
          PORT: '80',
          URL: 'chatforfree.ru',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:20:10 PM',
          SIP: '192.168.0.30',
          SP: '55666',
          DIP: '214.214.167.94',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:19:48 PM',
          SIP: '192.168.0.44',
          SP: '45240',
          DIP: '218.24.114.208',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:17:52 PM',
          SIP: '192.168.0.19',
          SP: '31101',
          DIP: '103.40.104.165',
          PORT: '80',
          URL: 'thelastwebpage.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:17:06 PM',
          SIP: '192.168.0.11',
          SP: '52465',
          DIP: '190.41.46.190',
          PORT: '80',
          URL: 'thebestwebsite.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:15:39 PM',
          SIP: '192.168.0.94',
          SP: '63814',
          DIP: '102.172.101.36',
          PORT: '80',
          URL: 'freefood.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:15:35 PM',
          SIP: '192.168.0.47',
          SP: '48110',
          DIP: '151.94.198.15',
          PORT: '443',
          URL: 'searchforus.de',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:14:08 PM',
          SIP: '192.168.0.86',
          SP: '34075',
          DIP: '101.237.85.107',
          PORT: '80',
          URL: 'securethenet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:14:04 PM',
          SIP: '192.168.0.188',
          SP: '51745',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:12:22 PM',
          SIP: '192.168.0.95',
          SP: '42733',
          DIP: '103.136.14.126',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:11:53 PM',
          SIP: '192.168.0.215',
          SP: '62813',
          DIP: '181.139.24.22',
          PORT: '80',
          URL: 'pastebucket.cn',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:11:34 PM',
          SIP: '192.168.0.70',
          SP: '40821',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:10:35 PM',
          SIP: '192.168.0.218',
          SP: '54606',
          DIP: '124.169.173.216',
          PORT: '80',
          URL: 'funweb.cn',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:10:16 PM',
          SIP: '192.168.0.9',
          SP: '56757',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:10:04 PM',
          SIP: '192.168.0.112',
          SP: '35716',
          DIP: '45.100.47.99',
          PORT: '80',
          URL: 'stopthebotnet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:08:45 PM',
          SIP: '192.168.0.24',
          SP: '50582',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:08:08 PM',
          SIP: '192.168.0.36',
          SP: '37102',
          DIP: '78.151.16.233',
          PORT: '80',
          URL: 'chatforfree.ru',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 4:06:40 PM',
          SIP: '192.168.0.193',
          SP: '43363',
          DIP: '95.77.193.180',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:05:14 PM',
          SIP: '192.168.0.254',
          SP: '55947',
          DIP: '33.255.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:04:37 PM',
          SIP: '192.168.0.117',
          SP: '54959',
          DIP: '182.203.42.246',
          PORT: '80',
          URL: 'thelastwebpage.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:04:30 PM',
          SIP: '192.168.0.172',
          SP: '43947',
          DIP: '3.60.67.249',
          PORT: '80',
          URL: 'thebestwebsite.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:04:21 PM',
          SIP: '192.168.0.134',
          SP: '60525',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:03:48 PM',
          SIP: '192.168.0.64',
          SP: '44114',
          DIP: '127.36.104.33',
          PORT: '443',
          URL: 'searchforus.de',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:02:42 PM',
          SIP: '192.168.0.250',
          SP: '57111',
          DIP: '243.223.175.143',
          PORT: '80',
          URL: 'securethenet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:01:34 PM',
          SIP: '192.168.0.132',
          SP: '60561',
          DIP: '33.225.130.104',
          PORT: '80',
          URL: 'chzweb.tilapia.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:01:33 PM',
          SIP: '192.168.0.23',
          SP: '57360',
          DIP: '239.141.52.189',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 4:01:01 PM',
          SIP: '192.168.0.215',
          SP: '44179',
          DIP: '161.192.122.40',
          PORT: '80',
          URL: 'healthreport.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:59:52 PM',
          SIP: '192.168.0.121',
          SP: '56315',
          DIP: '204.190.57.150',
          PORT: '80',
          URL: 'freefood.com',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:58:56 PM',
          SIP: '192.168.0.18',
          SP: '60624',
          DIP: '169.43.139.3',
          PORT: '80',
          URL: 'bestpurchase.com',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:58:54 PM',
          SIP: '192.168.0.106',
          SP: '30163',
          DIP: '110.234.67.223',
          PORT: '80',
          URL: 'visitorcenter.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:57:59 PM',
          SIP: '192.168.0.59',
          SP: '33145',
          DIP: '209.240.152.67',
          PORT: '80',
          URL: 'bestpurchase.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:57:03 PM',
          SIP: '192.168.0.27',
          SP: '46987',
          DIP: '23.83.170.116',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:55:14 PM',
          SIP: '192.168.0.211',
          SP: '31442',
          DIP: '168.83.234.163',
          PORT: '80',
          URL: 'visitorcenter.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:54:31 PM',
          SIP: '192.168.0.152',
          SP: '30520',
          DIP: '141.217.181.243',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:57:03 PM',
          SIP: '192.168.0.27',
          SP: '46987',
          DIP: '23.83.170.116',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:55:14 PM',
          SIP: '192.168.0.211',
          SP: '31442',
          DIP: '168.83.234.163',
          PORT: '80',
          URL: 'visitorcenter.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:54:31 PM',
          SIP: '192.168.0.152',
          SP: '30520',
          DIP: '141.217.181.243',
          PORT: '80',
          URL: 'goodguys.se',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:52:47 PM',
          SIP: '192.168.0.253',
          SP: '36463',
          DIP: '79.115.201.191',
          PORT: '80',
          URL: 'pastebucket.cn',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:51:44 PM',
          SIP: '192.168.0.244',
          SP: '61719',
          DIP: '14.47.142.43',
          PORT: '80',
          URL: 'bestpurchase.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:51:19 PM',
          SIP: '192.168.0.65',
          SP: '48611',
          DIP: '146.104.226.192',
          PORT: '80',
          URL: 'funweb.cn',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:49:54 PM',
          SIP: '192.168.0.126',
          SP: '40815',
          DIP: '171.140.162.96',
          PORT: '80',
          URL: 'stopthebotnet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:49:07 PM',
          SIP: '192.168.0.9',
          SP: '47625',
          DIP: '18.23.47.44',
          PORT: '80',
          URL: 'stopthebotnet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:47:38 PM',
          SIP: '192.168.0.131',
          SP: '44579',
          DIP: '139.58.55.91',
          PORT: '80',
          URL: 'funweb.cn',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:45:58 PM',
          SIP: '192.168.0.186',
          SP: '62683',
          DIP: '31.133.137.225',
          PORT: '80',
          URL: 'chatforfree.ru',
          REQUEST: 'POST',
        },
        {
          DT: '3/7/2016 3:44:05 PM',
          SIP: '192.168.0.181',
          SP: '38937',
          DIP: '150.119.71.249',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:43:33 PM',
          SIP: '192.168.0.225',
          SP: '46999',
          DIP: '131.97.167.36',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:42:56 PM',
          SIP: '192.168.0.150',
          SP: '35167',
          DIP: '152.203.213.16',
          PORT: '80',
          URL: 'thelastwebpage.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:42:06 PM',
          SIP: '192.168.0.133',
          SP: '62976',
          DIP: '206.194.229.42',
          PORT: '80',
          URL: 'thebestwebsite.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:40:21 PM',
          SIP: '192.168.0.225',
          SP: '45854',
          DIP: '38.212.240.180',
          PORT: '80',
          URL: 'freefood.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:39:43 PM',
          SIP: '192.168.0.128',
          SP: '44304',
          DIP: '180.208.164.237',
          PORT: '443',
          URL: 'searchforus.de',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:37:58 PM',
          SIP: '192.168.0.186',
          SP: '30386',
          DIP: '82.190.10.236',
          PORT: '80',
          URL: 'securethenet.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:37:49 PM',
          SIP: '192.168.0.123',
          SP: '42463',
          DIP: '252.77.216.60',
          PORT: '80',
          URL: 'healthreport.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:35:59 PM',
          SIP: '192.168.0.95',
          SP: '34447',
          DIP: '133.136.173.36',
          PORT: '80',
          URL: 'anti-malware.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:35:38 PM',
          SIP: '192.168.0.177',
          SP: '38107',
          DIP: '100.3.194.158',
          PORT: '80',
          URL: 'healthreport.com',
          REQUEST: 'GET',
        },
        {
          DT: '3/7/2016 3:34:24 PM',
          SIP: '192.168.0.189',
          SP: '42791',
          DIP: '208.238.143.104',
          PORT: '80',
          URL: 'freefood.com',
          REQUEST: 'POST',
        },
      ],

      SIEMLogsPopup: false,
      SIEMLogs: [
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:23:29 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '505',
          PNAME: 'excel.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:21:44 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '522',
          PNAME: 'winword.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '435',
          PNAME: 'cmd.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:22 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '558',
          PNAME: 'winlog.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:11 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '1900',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:18:53 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '1067',
          PNAME: 'notepad.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:18:34 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A new process has exited',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '1709',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:17:53 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '459',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:16:33 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '507',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:14:34 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.188',
          AN: 'kmatthews',
          PID: '1234',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:13 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.132',
          AN: 'jshmo',
          PID: '1517',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:50 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1144',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:07 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '533',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:46 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '979',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:32 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1889',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '151',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:56 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '1583',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:40 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:39 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '682',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:28 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1831',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:11 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1912',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:48 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '635',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:46 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.9',
          AN: 'Ibalk',
          PID: '907',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:42 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '520',
          PNAME: 'iexplore.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:01 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '392',
          PNAME: 'chrome.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:02 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '724',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:03 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1398',
          PNAME: 'putty.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:23 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '410',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:15 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1566',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:07:37 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '864',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:27 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '895',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '87',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '162',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:08:08 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:51 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '886',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:26 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '127',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:05:46 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '847',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:04:24 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '718',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:03 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1398',
          PNAME: 'putty.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:23 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '410',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:15 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1566',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:07:37 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '864',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:27 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '895',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '87',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '162',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:08:08 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:51 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '886',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:26 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '127',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:05:46 PM',
          EID: '4624',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '847',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:04:24 PM',
          EID: '4689',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '718',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:02:47 PM',
          EID: '4688',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '730',
          PNAME: 'firefox.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:02:04 PM',
          EID: '4689',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.132',
          AN: 'asmith',
          PID: '127',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:00:26 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1481',
          PNAME: 'time.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:58:43 PM',
          EID: '4688',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '645',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:58:40 PM',
          EID: '4624',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1992',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:57:15 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '654',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:56:13 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '1323',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:55:04 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1034',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:23:29 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '505',
          PNAME: 'excel.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:21:44 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '522',
          PNAME: 'winword.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '435',
          PNAME: 'cmd.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:22 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '558',
          PNAME: 'winlog.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:20:11 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '1900',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:18:53 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '1067',
          PNAME: 'notepad.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:18:34 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '1709',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:17:53 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '459',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:16:33 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '507',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:14:34 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.188',
          AN: 'kmatthews',
          PID: '1234',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:13 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.132',
          AN: 'jshmo',
          PID: '1517',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:50 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1144',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:07 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '533',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:46 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '979',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:32 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1889',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:12:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '151',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:56 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '1583',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:40 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:39 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '682',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:28 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1831',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:11 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '1912',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:48 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '635',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:46 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.9',
          AN: 'Ibalk',
          PID: '907',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:42 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '520',
          PNAME: 'iexplore.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:01 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '392',
          PNAME: 'chrome.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:13:02 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '724',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:03 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1398',
          PNAME: 'putty.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:23 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '410',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:15 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1566',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:07:37 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '864',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:27 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '895',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited.',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '87',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '162',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:08:08 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:51 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '886',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:26 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '127',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:05:46 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '847',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:04:24 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '718',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:11:03 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1398',
          PNAME: 'putty.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:23 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '410',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:15 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1566',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:07:37 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '864',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:27 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '895',
          PNAME: 'explorer.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:10:23 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '87',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:09:00 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '162',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:08:08 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '638',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:51 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '886',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:06:26 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '127',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:05:46 PM',
          EID: '4634',
          TC: 'Logoff',
          LM: 'An accounts was logged off',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '847',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:04:24 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.82',
          AN: 'gromney',
          PID: '718',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:02:47 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.134',
          AN: 'asmith',
          PID: '730',
          PNAME: 'firefox.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:02:04 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.132',
          AN: 'asmith',
          PID: '127',
          PNAME: 'mailclient.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 4:00:26 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1481',
          PNAME: 'time.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:58:43 PM',
          EID: '4689',
          TC: 'Process Termination',
          LM: 'A process has exited',
          IP: '192.168.0.43',
          AN: 'SYSTEM',
          PID: '645',
          PNAME: 'svchost.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:58:40 PM',
          EID: '4688',
          TC: 'Process Creation',
          LM: 'A new process has been created',
          IP: '192.168.0.141',
          AN: 'dfritz',
          PID: '1992',
          PNAME: 'outlook.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:57:15 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.104',
          AN: 'kwilliams',
          PID: '654',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:56:13 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.70',
          AN: 'cpuziss',
          PID: '1323',
          PNAME: 'Isass.exe',
        },
        {
          Keywords: 'Audit Success',
          DT: '3/7/2016 3:55:04 PM',
          EID: '4624',
          TC: 'Logon',
          LM: 'An accounts was successfully logged on',
          IP: '192.168.0.24',
          AN: 'jlee',
          PID: '1034',
          PNAME: 'Isass.exe',
        },
      ],

      phishingEmailPopup: false,
    };
  },
  methods: {
    togglePopup(popup) {
      if (this[popup] === true) {
        this[popup] = 'transition_out';
        return setTimeout(() => {
          this[popup] = false;
        }, 400);
      }
      this[popup] = true;
    },
    markSimulation() {
      let success = true;
      let totalScore = 0;

      if (this.usersClickedLink.value === this.usersClickedLink.answer) {
        totalScore++;
        this.usersClickedLink.marked = true;
      } else {
        success = false;
        this.usersClickedLink.marked = false;
      }

      if (this.workstationsInfected.value === this.workstationsInfected.answer) {
        totalScore++;
        this.workstationsInfected.marked = true;
      } else {
        success = false;
        this.workstationsInfected.marked = false;
      }

      if (this.malwareName.value === this.malwareName.answer) {
        totalScore++;
        this.malwareName.marked = true;
      } else {
        success = false;
        this.malwareName.marked = false;
      }
      this.reportAnalysisComplete = success;

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.line-across {
  position: absolute;
  width: 65.875%;
  height: 4px;
  background-color: #0285e4;
}

.line-up {
  position: absolute;
  width: 4px;
  height: 200px;
  background-color: #0285e4;
}

.server-container {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 165px;
  padding: 10px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 0.5rem;
  -o-object-fit: fill;
  object-fit: fill;
}

.server-block {
  background: #1dd1a1;
}

.vs-switch {
  background: #000000ab;
}
</style>
