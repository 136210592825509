import { render, staticRenderFns } from "./Lgx344089537430945792.vue?vue&type=template&id=bc3cb966&scoped=true&"
import script from "./Lgx344089537430945792.vue?vue&type=script&lang=js&"
export * from "./Lgx344089537430945792.vue?vue&type=script&lang=js&"
import style0 from "./Lgx344089537430945792.vue?vue&type=style&index=0&id=bc3cb966&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc3cb966",
  null
  
)

export default component.exports